import "./styles/index.css";
import Header from "./components/Header";
import About from "./components/About";
import Features from "./components/Features";
import Comparison from "./components/Comparison";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import _ from "lodash";
import { useEffect } from "react";
import Introduction from "./components/Introduction";

function App() {
  const changeNav = _.debounce((entries, observer) => {
    entries.forEach((entry) => {
      // verify the element is intersecting
      if (entry.isIntersecting && entry.intersectionRatio >= 0.55) {
        // remove old active class

        document.querySelector(".active").classList.remove("active");
        // get id of the intersecting section
        let id = entry.target.getAttribute("id");
        // find matching link & add appropriate class
        document.querySelector(`[href="#${id}"]`).classList.add("active");
        window.location.replace(`#${id}`);
      }
    });
  }, 500);
  const options = {
    threshold: 0.55,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(changeNav, options);
    const sections = document.querySelectorAll("section, header, footer");
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      // if(containerRef.current) observer.unobserve(containerRef.current)
    };
  }, [changeNav]);

  useEffect(() => {
    !window.location.hash && window.location.replace("#home");
  }, []);

  return (
    <main>
      <Navbar />
      <Header />
      <Introduction />
      <About />
      <Features />
      <Comparison />
      <Contact />
      <Footer />
    </main>
  );
}

export default App;
