import React from "react";
import { FaEnvelope, FaTwitter, FaYoutube } from "react-icons/fa";
const Footer = () => {
  return (
    <footer id="sharing">
      <div>
        <div>
          <img src="/logo-white.svg" alt="" />
        </div>
        <div>
          <p>© {new Date().getFullYear()} Factly. All rights reserved.</p>
        </div>
        <div className="social-icons">
          <a
            href="mailto:dataful@factly.in"
            target="_blank"
            title="Mail to us"
            rel="noopener noreferrer"
          >
            <FaEnvelope />
          </a>
          <a
            href="https://twitter.com/Dataful_factly"
            target="_blank"
            title="Follow us on Twitter"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.youtube.com/channel/UCmQX08zkTh3VqiHgEBiuh6A"
            target="_blank"
            title="Subscribe to our Youtube Channel"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
