import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import useHash from "./utils/useHash";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [hash, setHash] = useHash();
  const [textColor, setTextColor] = useState("text-white");

  const container = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
    exit: {
      transition: {
        delay: 0.5,
        delayChildren: 0.1,
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
      opacity: 0,
    },
  };
  const item = {
    hidden: {
      opacity: 0,
      y: 300,
    },
    exit: {
      opacity: 0,
      y: 300,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 0.8,
      },
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 0.8,
      },
    },
  };

  const links = [
    { path: "#home", name: "Home" },
    { path: "#introduction", name: "Introduction" },
    { path: "#about", name: "About" },
    { path: "#features", name: "Features" },
    { path: "#comparison", name: "Comparison" },
    { path: "#contact", name: "Contact" },
    { path: "#sharing", name: "Sharing" },
  ];

  useEffect(() => {
    setTextColor(
      ["home", "contact", "sharing"].includes(hash.slice(1))
        ? "text-white"
        : "text-black"
    );
  }, [hash]);

  return (
    <>
      <div className={`logo-container ${textColor}`}>
        <a href="#home">
          <img
            src={
              ["contact", "sharing"].includes(hash.slice(1))
                ? "/logo-white.png"
                : "/logo.png"
            }
            alt=""
          />
          <p>A product of Factly</p>
        </a>
        <div>
          <button onClick={() => setOpen(true)}>
            Menu <FaBars />
          </button>
        </div>
      </div>
      <AnimatePresence initial="hidden">
        {open && (
          <motion.div
            key="menu-items"
            className="menu-container"
            initial="hidden"
            variants={container}
            animate="show"
            exit="exit"
            viewport={{ once: true, amount: 0.2 }}
            onClick={() => setOpen(false)}
          >
            <button className="close-button" onClick={() => setOpen(false)}>
              <FaTimes />
            </button>
            <div className="menu-items">
              <a href="#home">
                {" "}
                <motion.img src="/logo.svg" alt="" variants={item} />
              </a>
              {links.map((link) => {
                return (
                  <motion.a href={link.path} variants={item} key={link.path}>
                    {link.name}
                  </motion.a>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div
        id="fp-nav"
        className={`fp-show-active right ${textColor}`}
        style={{ marginTop: "-471.5px" }}
      >
        <ul>
          {links.map((link) => {
            return (
              <li key={link.path}>
                <a
                  href={link.path}
                  className={hash === link.path ? "active" : ""}
                >
                  <span></span>
                </a>
                <div className="fp-tooltip right">{link.name}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
