import React from "react";
import { motion } from "framer-motion";

const Introduction = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 300 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 0.8,
      },
    },
  };

  return (
    <motion.section
      id="introduction"
      className="intro-section"
      initial="hidden"
      variants={container}
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
    >
      <div className="intro-content">
        <motion.h2 variants={item}>Introduction</motion.h2>
        <motion.div variants={item} className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/L1xVOuLMlgw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Introduction;
