import React, { useState } from "react";
import { motion } from "framer-motion";
import { useForm } from "@formspree/react";

const Contact = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: "",
  });

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 300 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 0.8,
      },
    },
  };

  const [state, handleSubmit] = useForm("mjvzypkn");

  const handleClick = async (e) => {
    e.preventDefault();
    await handleSubmit(e);
    state.succeeded &&
      setFormData({ first_name: "", last_name: "", email: "", message: "" });
  };

  return (
    <motion.section
      id="contact"
      initial="hidden"
      variants={container}
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
      className="contact-section"
    >
      <div className="contact-image-container">
        <motion.img variants={item} src="/contact.svg" alt="" />
      </div>
      <div className="contact-content">
        {!state.succeeded ? (
          <motion.form action="" variants={item} onSubmit={handleClick}>
            <div>
              <label htmlFor="first_name">
                First Name
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="First Name"
                  value={formData.first_name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...{ prev },
                      first_name: e.target.value,
                    }))
                  }
                />
              </label>
              <label htmlFor="last_name">
                Last Name
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Last Name"
                  value={formData.last_name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...{ prev },
                      last_name: e.target.value,
                    }))
                  }
                />
              </label>
            </div>
            <div>
              <label htmlFor="email">
                Email
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...{ prev },
                      email: e.target.value,
                    }))
                  }
                />
              </label>
            </div>
            <div>
              <label htmlFor="message">
                Message
                <textarea
                  id="message"
                  name="message"
                  placeholder="Enter your message"
                  value={formData.message}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...{ prev },
                      message: e.target.value,
                    }))
                  }
                />
              </label>
            </div>

            <button type="submit">Send Message</button>
          </motion.form>
        ) : (
          <div>Your Response has been submitted!</div>
        )}
      </div>
    </motion.section>
  );
};

export default Contact;
