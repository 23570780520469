import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import initStompBits from "./utils/stompBits";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { FaEnvelope, FaTwitter, FaYoutube, FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import { useForm } from "@formspree/react";

const Header = () => {
  const url =
    "https://dataful.us18.list-manage.com/subscribe/post?u=c656d8781fac5de960164ac90&amp;id=5e621b3892&amp;f_id=006519e7f0";
  const sectors = [
    "Administration and Governance",
    "Agriculture and Allied",
    "Art and Culture",
    "Automobile",
    "Aviation",
    "Banking",
    "Biotechnology",
    "Census and Surveys",
    "Chemicals and Fertilizers",
    "Commerce",
    "Corporate",
    "Defence",
    "Demographics",
    "Education",
    "Electoral Statistics",
    "Entertainment",
    "Environment and Forest",
    "Finance",
    "Food",
    "Foreign Affairs",
    "Health and Family Welfare",
    "Home Affairs and Enforcement",
    "Housing",
    "Industries and Factories",
    "Information and Broadcasting",
    "Information Technology",
    "Infrastructure",
    "Insurance",
    "International",
    "Irrigation",
    "Labour and Employment",
    "Law and Justice",
    "Media",
    "Mining",
    "Parliament of India",
    "Ports and Shipping",
    "Postal",
    "Power and Energy",
    "Railways",
    "Rural Development",
    "Science and Technology",
    "Social Development",
    "State Functions",
    "Telecommunications",
    "Tourism and Hospitality",
    "Trade",
    "Transport",
    "Urban Development",
    "Water and Sanitation",
    "Water Resources",
    "Youth and Sports",
  ];
  const [email, setEmail] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formStatus, setFormStatus] = useState(null);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleMailChimpSubmit = (e, onValidated) => {
    setFormStatus(null);
    e.preventDefault();
    email.length > 0 &&
      // name &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    defines: "",
    organisation: "",
    interests: [],
  });

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 300 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 0.8,
      },
    },
  };

  const [state, handleSubmit] = useForm("xnqrponk");

  const handleClick = async (e) => {
    e.preventDefault();
    await handleSubmit(e);

    state.succeeded &&
      setFormData({
        first_name: "",
        last_name: "",
        defines: "",
        organisation: "",
        interests: "",
      });
  };

  useEffect(() => {
    initStompBits();
  }, []);

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  useEffect(() => {
    formStatus === "success" && openModal();
  }, [formStatus]);

  return (
    <motion.header
      id="home"
      initial="hidden"
      variants={container}
      whileInView="show"
      viewport={{ once: true, amount: 0.2 }}
    >
      <div className="header-content-container">
        <div className="header-content">
          <div className="header-text-content">
            <div className="header-main">
              <div>
                <motion.h3 variants={item}>Launching soon... </motion.h3>
                <motion.p variants={item} className="header-description">
                  Making the process of working with public data easy, fast, and
                  simple
                </motion.p>
              </div>
              <motion.div variants={item}>
                <p className="input-info">
                  For updates & to download a sample dataset
                </p>
                {/* <div className="input-container">
                  <input type="email" placeholder="Enter your email" />
                  <button>Submit</button>
                </div> */}
                <MailchimpSubscribe
                  url={url}
                  render={({ subscribe, status, message }) => (
                    <div className="input-container">
                      {status === "success" && setFormStatus(status)}

                      <div className="input-group">
                        <input
                          type="email"
                          placeholder="Enter your email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {status === "sending" && (
                          <div style={{ color: "#fff" }}>sending...</div>
                        )}
                        {status === "error" && (
                          <div
                            style={{ color: "red" }}
                            dangerouslySetInnerHTML={{ __html: message }}
                          />
                        )}
                        {status === "success" && (
                          <div style={{ color: "#fff" }}>Subscribed !</div>
                        )}
                      </div>

                      <button
                        type="submit"
                        onClick={(e) =>
                          handleMailChimpSubmit(e, (formData) =>
                            subscribe(formData)
                          )
                        }
                      >
                        Submit
                      </button>
                    </div>
                  )}
                />
              </motion.div>{" "}
            </div>
            <motion.div className="social-icons" variants={item}>
              <a
                href="mailto:dataful@factly.in"
                target="_blank"
                title="Mail to us"
                rel="noopener noreferrer"
              >
                <FaEnvelope />
              </a>
              <a
                href="https://twitter.com/Dataful_factly"
                target="_blank"
                title="Follow us on Twitter"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
              <a
                href="https://www.youtube.com/channel/UCmQX08zkTh3VqiHgEBiuh6A"
                target="_blank"
                title="Subscribe to our Youtube Channel"
                rel="noopener noreferrer"
              >
                <FaYoutube />
              </a>
            </motion.div>
          </div>
        </div>
        <div
          id="ambient-bg"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: "-1",
          }}
        >
          <canvas
            id="bits"
            style={{ position: "absolute", bottom: 0, left: 0, zIndex: "-2" }}
          />
        </div>
      </div>
      <div className="header-image-container">
        <motion.img variants={item} src="/header.svg" alt="" />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Download Sample Dataset by filling this form"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div className="modal-container" variants={item}>
          <div>
            <h4>Download Sample Dataset by filling this form</h4>
            <button onClick={closeModal}>
              <FaTimes aria-label="modal close button" />
            </button>
          </div>
          <hr />
          {!state.succeeded ? (
            <form onSubmit={handleClick}>
              <label htmlFor="first_name">
                First Name
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="First Name"
                  value={formData.first_name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...{ prev },
                      first_name: e.target.value,
                    }))
                  }
                />
              </label>
              <label htmlFor="last_name">
                Last Name
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Last Name"
                  value={formData.last_name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...{ prev },
                      last_name: e.target.value,
                    }))
                  }
                />
              </label>

              <label htmlFor="defines">
                What best defines you?
                <select
                  id="defines"
                  name="defines"
                  placeholder=""
                  value={formData.defines}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...{ prev },
                      defines: e.target.value,
                    }))
                  }
                >
                  <option value="Media">Media/Journalist</option>
                  <option value="Researcher">Researcher</option>
                  <option value="Fact-checker">Fact-checker</option>
                  <option value="Freelancer">Freelancer</option>
                  <option value="Social Media Activist">
                    Social Media Influencer
                  </option>
                  <option value="Activist">Activist</option>
                  <option value="Blogger">Blogger</option>
                  <option value="Educator">Educator</option>
                  <option value="Student">Student</option>
                  <option value="Data Analyst">Data Analyst</option>
                  <option value="Data Visualizer">Data Visualizer</option>
                  <option value="Data Scientist">Data Scientist</option>
                  <option value="Other">Other</option>
                </select>
              </label>
              <label htmlFor="organisation">
                Organisation/College Name: (optional)
                <input
                  type="text"
                  id="organisation"
                  name="organisation"
                  placeholder="Organisation/College Name"
                  value={formData.organisation}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...{ prev },
                      organisation: e.target.value,
                    }))
                  }
                />
              </label>

              <div>
                <label htmlFor="message">
                  Interested Areas
                  <select
                    multiple
                    id="interests"
                    name="interests"
                    placeholder="Interests"
                    value={formData.interests}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...{ prev },
                        interests: e.option,
                      }))
                    }
                  >
                    {sectors.map((sector) => (
                      <option value={sector} key={sector}>
                        {sector}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <button type="submit">Download the sample dataset</button>
            </form>
          ) : (
            <div>
              <a
                href="https://drive.google.com/file/d/1LFWISVLC3hXStUnAG6pPmmmG8DrdHITZ/view"
                target="_blank"
                rel="noreferrer"
              >
                Download the sample dataset
              </a>
            </div>
          )}
        </div>
      </Modal>
    </motion.header>
  );
};

export default Header;
